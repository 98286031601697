<template>
  <v-skeleton-loader type="actions" v-if="loadingCategories || !currentProduct"></v-skeleton-loader>
  <div v-else>
    <v-container fluid class="page-bg pa-0">
      <v-container class="text-center py-0 pl-0 pr-0">
        <!-- <img width="100%" :src="require('../assets/banners/top.jpg')" /> -->
      </v-container>
    </v-container>
    <v-container grid-list-lg class="mt-3">
      <v-layout row wrap>
        <v-flex md12>
          <v-toolbar flat dense class="navigation">
            <v-btn small text @click="backButton" class="pl-0">
              <v-icon left>mdi-chevron-left</v-icon>
              {{ $t("product.backToCategory") }}
            </v-btn>
          </v-toolbar>
        </v-flex>
      </v-layout>
      <v-layout row wrap>
        <v-flex md6>
          <v-skeleton-loader type="image" v-if="loadingCategories"></v-skeleton-loader>
          <v-img :src="getProductImage(imageData)"></v-img>
        </v-flex>
        <v-flex md6>
          <product-detail />
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import ProductDetail from "../components/Product/ProductDetail";
import { getProductImage } from "@/Helper";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ProductPage",
  metaInfo() {
    return {
      title: this.currentProduct ? this.currentProduct.name : "",
    };
  },
  mixins: [getProductImage],
  components: {
    ProductDetail,
  },
  computed: {
    ...mapGetters(["currentCategory", "allProducts", "currentProduct", "loadingCategories"]),
    imageData() {
      if (this.currentProduct) {
        return this.currentProduct.imageUrl;
      }

      return null;
    },
    title() {
      if (this.currentCategory) {
        return this.currentCategory.name;
      }

      return "Obchod";
    },
    titleColor() {
      if (this.currentCategory && this.currentCategory.customData && this.currentCategory.customData.backgroundColor) {
        return this.currentCategory.customData.backgroundColor;
      }

      return "#9b9c9c";
    },
  },
  watch: {
    currentProduct(currentProd) {
      if (currentProd) {
        this.changeCategory({ categoryId: currentProd.productCategoryId, langChange: false });
      }
    }
  },
  mounted() {
    let productAlias = this.$router.currentRoute.params;
    if (typeof productAlias.alias !== "undefined") {
      this.changeProduct({ langChange: false });
    }
  },
  methods: {
    ...mapActions(["changeProduct", "changeCategory"]),
    backButton() {
      if (this.currentCategory) {
        this.$router.push("/category/" + this.currentCategory.alias);
        return;
      }
      this.$router.push("/");
    }
  },
};
</script>

<style lang="scss">
.main-title {
  display: table;
  height: 50px;
  width: 100%;
  margin-top: 0;
}

.main-title h1 {
  font-size: 1.5em;     
  text-transform: uppercase;
  font-weight: 600;
  color: black;
  display: table-cell;
  vertical-align: middle
}

.product-title {
  text-align: left;
  position: absolute;
  bottom: 30px;
  left: 10px;
  width: 120px;
  text-transform: uppercase;
  color: white;
  font-weight: 550;
  font-size: 1rem;
}

.navigation .v-toolbar__content {
  padding-left: 0;
}
</style>