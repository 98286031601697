<template>
  <div class="my-0 my-sm-4">
    <v-container class="main-header py-0 px-0 black" :class="{ isCheckout: isCheckout }">
      <v-toolbar flat>
        <v-btn @click="menu = true" class="mr-2 hamburger-menu" color="secondary" icon v-if="!isCheckout">
          <v-icon>mdi-menu</v-icon>
        </v-btn>
        <router-link to="/" class="main-link">
          <img :src="require('../../assets/logo_sirka.png')" class="custom-logo" :class="{ isCheckout: isCheckout }"
            itemprop="logo" v-if="$vuetify.breakpoint.smAndUp && !isCheckout" />
          <img v-else :src="require('../../assets/logo_sirka.png')" class="mobile-custom-logo" />
        </router-link>
        <v-spacer></v-spacer>
        <div class="main-menu" v-if="!isCheckout">
          <v-btn v-for="(category, index) in allCategories" :class="{ 'ml-1': index > 0 }" small color="primary" text
            :to="'/category/' + category.alias" :key="index">{{ category.name }}</v-btn>
        </div>
        <v-spacer></v-spacer>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn :small="$vuetify.breakpoint.xsOnly" color="primary" dark tile depressed v-bind="attrs" v-on="on"
              class="mr-1">
              {{ activeLocale }}
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item v-for="(item, index) in locales" :key="index" @click="changeLocale(item)">
              <v-list-item-title class="text-uppercase">{{
                item
              }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <div class="shopping-cart" :class="{ isCheckout: isCheckout }">
          <v-badge :content="itemsInCart" :value="itemsInCart" color="#9b9b9b" overlap
            :offset-y="$vuetify.breakpoint.xsOnly ? 20 : 0" :offset-x="$vuetify.breakpoint.xsOnly ? 20 : 0">         
            <v-btn icon color="primary" @click="drawer = true">
              <img :src="require('../../assets/kosik2.svg')" />
            </v-btn>
          </v-badge>
        </div>
      </v-toolbar>
    </v-container>
    <v-navigation-drawer temporary v-model="drawer" :right="true" fixed width="340">
      <cart-overlay @drawerClose="drawer = false" />
    </v-navigation-drawer>
    <v-navigation-drawer temporary absolute v-model="menu" fixed width="340">
      <v-toolbar dense flat>
        <v-toolbar-title class="text-uppercase font-weight-bold">{{
          $t("homepage.menuTitle")
        }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="menu = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider></v-divider>
      <v-list>
        <v-list-item :to="'/category/' + category.alias" v-for="(category, index) in allCategories" :key="index">
          <v-list-item-content>
            <v-list-item-title>{{ category.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CartOverlay from "../Cart/CartOverlay";
import { LOCALES } from "@/i18n";

export default {
  name: "MainHeader",
  props: ["isCheckout"],
  data() {
    return {
      drawer: false,
      menu: false,
      locales: LOCALES,
      activeLocale: null,
    };
  },
  mounted() {
    this.activeLocale = this.$i18n.locale;
  },
  components: {
    CartOverlay,
  },
  computed: {
    ...mapGetters(["currentCategory", "cartProducts", "allCategories"]),
    itemsInCart() {
      let cart = this.cartProducts;
      if (!cart[0]) return null;
      return cart.reduce((accum, item) => accum + item.qty, 0);
    },
  },
  methods: {
    changeLocale(item) {
      this.activeLocale = item;
      this.$i18n.locale = item;
      this.$store.dispatch("setLang", item);
    },
  },
};
</script>

<style lang="scss">
.theme--light.v-toolbar.v-sheet {
  background-color: transparent;
}

.main-header {
  .v-toolbar__content {
    padding: 0;

    @media (max-width: 1270px) {
      padding: 4px 16px;
    }
  }

  .main-menu {
    @media (max-width: 1270px) {
      display: none;
    }
  }

  .main-menu a {
    color: white !important;
  }

  .hamburger-menu {
    display: none;

    @media (max-width: 1270px) {
      display: flex;
    }
  }

  .main-link {
    line-height: 0;
  }

  .main-cart {
    display: inline-flex;
    cursor: pointer;
  }

  .cart-title {
    display: inline-flex;
    margin-top: 5px;
    color: black // #ffffff;
  }

  .cart-image {
    cursor: pointer;
    width: 40px;
  }

  .mobileCart {
    max-width: 80px;
  }

  &.isCheckout {
    padding-top: 0;
    padding-bottom: 0;
  }

  .menu-item {
    a {
      padding: 0 1em;
      font-size: 16px;
      color: #ffffff !important;
      text-decoration: none;
      text-transform: uppercase;

      &:hover {
        opacity: 0.7;
      }
    }
  }

  .main-title {
    color: #ffffff;
    font-weight: 300;
    text-transform: uppercase;
    margin-left: 40px;

    strong {
      display: block;
      font-weight: 700;
      font-size: 32px;
      line-height: 32px;
    }
  }

  .mobile-custom-logo {
    max-width: 100px;
  }

  .custom-logo {
    max-width: 136px;

    &.isCheckout {
      max-width: 136px;
    }
  }

  .menu-icon-link {
    border-radius: 7px !important;
  }

  .v-list,
  .v-menu__content {
    border-radius: 0 !important;
  }

  .v-menu__content {
    max-width: 100% !important;
    width: 100%;
    left: 0 !important;
  }

  .v-chip {
    .v-chip__content {
      cursor: pointer !important;
    }
  }

  .shopping-cart {
    &.isCheckout {
      display: none;
    }
  }
}
</style>
