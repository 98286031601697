<template>
  <v-container grid-list-lg class="pl-0 pr-0">
    <v-layout row wrap v-if="loadingCategories">
      <v-flex xs12 sm6 lg3 v-for="n in 3" :key="n">
        <v-skeleton-loader type="card"></v-skeleton-loader>
      </v-flex>
    </v-layout>
    <v-item-group multiple justify-center>
      <v-container>
        <v-row>
<!--           <v-col v-if="!loadingCategories && !allProducts.length">
            <h3>{{ $t('homepage.itemsNotFound') }}</h3>
          </v-col>
 -->          <v-col v-for="product in allProducts" :key="product.id" cols="12" md="6">
            <v-item v-slot="{ isSelected, selectedClass }">
              <v-card :class="['fill-height', 'd-flex', selectedClass]" dark flat
                @click="$router.push('/product/' + product.alias)">
                <v-container class="pa-0">
                  <v-row no-gutters class="fill-height">
                    <v-col sm="7" xl="6" xsm="12">
                      <v-img cover height="100%" :src="getProductImage(product.imageUrl)"></v-img>
                    </v-col>
                    <v-col sm="5" xl="6" xsm="12" order-sm="last">
                      <v-container class="fill-height">
                        <v-row>
                          <v-col cols="12">
                            <span class="text-overline" style="line-height: initial;">{{
                              product.tags ? product.tags.join(', ') : '' }}</span>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <div>
                              {{ new Date(product.eventDate).toLocaleDateString() }}
                            </div>
                            <div class="font-weight-bold text-uppercase pt-3 pb-3">{{
                              product.name }}</div>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <div v-html="formatTime(product.eventDate)">
                            </div>
                          </v-col>
                          <v-col cols="6" class="text-right">
                            <img width="24" :src="require('../../assets/kosik2.svg')" />
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-item>
          </v-col>
        </v-row>
      </v-container>
    </v-item-group>
  </v-container>
</template>

<script>
import { getProductImage, formatTime } from "@/Helper";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Products",
  mixins: [getProductImage, formatTime],
  computed: {
    ...mapGetters(["allProducts", "loadingCategories"]),
  },
  watch: {
    allProducts: function (newValue) {
      if (newValue) {
        if (this.$gtm.enabled()) {
          let products = [];

          for (let i in this.allProducts) {
            products.push({
              id: this.allProducts[i].productId,
              name: this.allProducts[i].name,
              price: this.allProducts[i].amountWithVat,
              category: this.allProducts[i].productCategoryName,
              list: "Home Page",
              position: i + 1,
            });
          }
          this.$gtm.trackEvent({
            event: "productImpression", // Event type [default = 'interaction'] (Optional)
            category: "Ecommerce",
            ecommerce: {
              impressions: products,
            },
          });
        }
      }
    },
  },
  methods: {
    ...mapActions(["addToCart"]),  
  }
};
</script>

<style lang="scss"></style>
