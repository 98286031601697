<template>
  <div class="product-detail">
    <v-skeleton-loader type="article" v-if="loadingCategories || !currentProduct"></v-skeleton-loader>
    <div v-else>
      <h1 class="text-uppercase">{{ currentProduct.name }}</h1>
      <span class="text-overline" style="line-height: initial;">{{
        currentProduct.tags ? currentProduct.tags.join(', ') : '' }}</span>

      <p class="price primary--text mt-4 mb-6">
        {{ new Date(currentProduct.eventDate).toLocaleDateString() }}&nbsp;&nbsp;<span
          v-html="formatTime(currentProduct.eventDate)"></span>
      </p>

      <div v-html="currentProduct.description" class="product-description mb-6"></div>
      <p>{{ $t("product.note") }}</p>
      <p class="price primary--text font-weight-bold mt-4 mb-6">
        {{
          $t("homepage.currency", {
            price: formatThousands(currentProduct.amountWithVat),
          })
        }}
      </p>
      <v-layout class="mb-3 ml-0">
        <vue-numeric-input v-model="qty" :min="1" :max="10" align="center" size="100px" class="ma-0"></vue-numeric-input>
        <v-btn class="ml-3" color="primary" tile depressed @click="addToCart({ product: currentProduct, qty: qty })">{{
          $t("cart.addToCart") }}</v-btn>
      </v-layout>
    </div>
  </div>
</template>

<script>
import VueNumericInput from "vue-numeric-input";
import { mapGetters, mapActions } from "vuex";
import { formatThousands, formatTime } from "@/Helper";

export default {
  name: "ProductDetail",
  mixins: [formatThousands, formatTime],
  computed: {
    ...mapGetters(["currentProduct", "currentCategory", "loadingCategories"]),
  },
  components: {
    VueNumericInput,
  },
  data() {
    return {
      qty: 1,
    };
  },
  methods: {
    ...mapActions(["addToCart"]),
  },
};
</script>

<style lang="scss">
.product-detail {
  .product-description {
    p {
      margin-bottom: 0;
    }

    ;

    span {
      display: block;
      color: #777;

      &:first-child {
        margin-bottom: 20px;
        color: #000000;
      }
    }
  }

  .price {
    font-size: 22px;
  }

  .vue-numeric-input {
    input {
      outline: none;
      height: 100%;
    }

    .btn {
      background: transparent;
      box-shadow: none;
      border: none;
    }
  }
}</style>
