import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#000000',
                secondary: '#ffffff',
                info: '#4583f4',
                success: '#ffffff',
                error: '#e80028'
            },
            dark: {
                primary: '#000000',
                secondary: '#ffffff',
                info: '#4583f4',
                success: '#509c2c',
                error: '#e80028'
            }
        }
    }
});
