export const getProductImage = {
    methods: {
        getProductImage(image) {
            if (!image) {
                return require('../assets/products/noimage.jpg');
            }
            return image;
        }
    }
};

export const formatThousands = {
    methods: {
        formatThousands(item) {
            if (item) {
                return item.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
            }

            return 0;
        },
    }
};

export const formatTime = {
    methods: {
        formatTime(item) {
            if (item) {
                let dateTime = new Date(item);
                let minutes = ("0" + dateTime.getMinutes()).slice(-2);
                return dateTime.getHours() + (minutes >= 0 ? '<sup>' + minutes + '</sup>' : '') + ' h'
            }

            return '';
        },
    }
};
