<template>
  <v-container class="white--text">
    <v-row>
      <v-col cols="12" sm="6">
        <p class="mb-1 font-weight-bold">Divadelní spolek NES8</p>
        <p class="mb-0">Centrum RoSa</p>
        <p class="mb-2">Střelničná 1680, Praha 8 – Kobylisy</p>
        <p class="mb-1">Kontakty:</p>
        <p class="mb-0">umělecký šéf divadla : Ing. Lenka Krňávková</p>
        <p class="mb-0">ředitel divadla : Mgr. Kateřina Vyskočil Engelová</p>
      </v-col>
      <v-col cols="12" sm="6">        
        
        <p class="mb-4 font-weight-bold" ><a :href="$t('footer.privacyPolicyPdf')" class="no-decoration white--text" target="_blank">{{ $t('footer.privacyPolicy') }}</a></p>
        <p class="mb-1">PRONÁJEM SÁLU - veškeré informace získáte na</p>
        <p class="mb-2"><v-icon color="secondary">mdi-phone</v-icon>+420 721 055 555 nebo +420 776 478 800</p>
        <p>Sledujte nás <a href="https://www.instagram.com/divadlo.nes8" target="_blank" style="margin: .4rem; line-height: 28px;vertical-align: middle;">
            <v-icon color="secondary" size="28">mdi-instagram</v-icon></a>
        </p>        
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "FooterAddress",
};
</script>