<template>
    <v-container fluid class="pa-2 footer-class">        
        <footer-address />
    </v-container>
</template>

<script>
import Address from "./Address"

export default {
    name: 'Footer',
    props: ['isCheckout'],
    components: {
        'footer-address': Address
    }
}
</script>

<style lang="scss">
.footer-class {
    background-color: black;
}
</style>
